
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SettingInput",
  props: ["setting"],
  emits: ["update:modelValue", "update"],
  setup(props) {
    const data = ref(props.setting.value.data);

    return {
      data,
    };
  },
});
