<template>
  <div v-if="setting.type === 'string'">
    <label :for="setting.uid" class="form-label">{{ setting.description }}</label>
    <input
      type="text"
      class="form-control"
      v-model="data"
      :id="setting.uid"
      @input="$emit('update', data)"
      required
    />
  </div>
  <div v-else-if="setting.type === 'boolean'" class="form-check form-switch">
    <input
      class="form-check-input"
      v-model="data"
      type="checkbox"
      :id="setting.uid"
      @change="$emit('update', data)"
    />
    <label class="form-check-label" :for="setting.uid">
      {{
      setting.description
      }}
    </label>
  </div>
  <div v-else-if="setting.type === 'number'">
    <label :for="setting.uid" class="form-label">{{ setting.description }}</label>
    <input
      type="number"
      class="form-control"
      v-model.number="data"
      :id="setting.uid"
      @input="$emit('update', data)"
      step="any"
      required
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SettingInput",
  props: ["setting"],
  emits: ["update:modelValue", "update"],
  setup(props) {
    const data = ref(props.setting.value.data);

    return {
      data,
    };
  },
});
</script>

<style scoped></style>
