<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <div class="card-body">
        <form v-if="Object.keys(settings).length > 0" class @submit.prevent="Submit">
          <div v-for="settingUid in Object.keys(settings)" :key="settingUid" class="mb-3">
            <SettingInput
              :setting="settings[settingUid]"
              @update="mutableSettings[settingUid] = $event"
            />
          </div>

          <div class="text-center">
            <button class="btn btn-dark">Update Settings</button>
          </div>
        </form>
        <div v-else class="my-5">
          <h1 class="text-danger">No Settings found!</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SettingInput from "@/views/settings/constituent/SettingInput";
export default defineComponent({
  name: "SettingsForm",
  components: { SettingInput },
  props: ["settings"],
  emits: ["save"],
  setup(_, context) {
    const mutableSettings = {};
    function Submit() {
      context.emit("save", mutableSettings);
    }
    return {
      Submit,
      mutableSettings,
    };
  },
});
</script>

<style scoped></style>
