
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet, apiPatch } from "@/api";
import { Toast } from "@/utils/notify";
import SettingsForm from "@/views/settings/constituent/SettingsForm.vue";

export default defineComponent({
  name: "DynamicSettingsFor",
  props: ["type"],
  components: {
    SettingsForm,
    HourglassLoader,
  },
  setup(props) {
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/admin/misc/settings/${props.type}`);
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch settings: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    const SaveData = async (data: Record<string, unknown>) => {
      loading.value = true;
      try {
        const response = await apiPatch(
          `/admin/misc/settings/${props.type}`,
          data
        );
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch settings: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      SaveData,
      data,
      loading,
    };
  },
});
